import memoizee from "memoizee";
import {useRouter} from "next/router";

import {v5Pages} from "../components/_common/_constants";
import {CareSlug} from "../constants/cares";
import {MsMap} from "../constants/MsMap";
import {SpecialtyId} from "../constants/specialtyIds";
import {buildRegionSpecialtyMap} from "../hooks/useSpecialtiesInRegion";
import {useTypedSelector} from "../store";
import {selectSelectedRegion} from "../store/slices/userLocation";
import {doListsContainSameElements} from "./doListsContainSameElements";

type AllowedSpecialtyId = typeof allowedSpecialtyIds[number];

const allowedSpecialtyIds = [
  SpecialtyId.PRIMARY_CARE,
  SpecialtyId.URGENT_CARE,
  SpecialtyId.OCCUPATIONAL_HEALTH,
  SpecialtyId.COVID_TESTING_MOBILE,
  SpecialtyId.COVID_TRAVEL_CLEARANCE,
];

const isAllowedSpecialtyId = (val: unknown): val is AllowedSpecialtyId =>
  allowedSpecialtyIds.includes(val as AllowedSpecialtyId);

export const AllowedSpecialtyIdList = {
  ALL: allowedSpecialtyIds,
  WITHOUT_PC: [
    SpecialtyId.URGENT_CARE,
    SpecialtyId.OCCUPATIONAL_HEALTH,
    SpecialtyId.COVID_TESTING_MOBILE,
    SpecialtyId.COVID_TRAVEL_CLEARANCE,
  ],
  WITHOUT_OCC: [
    SpecialtyId.PRIMARY_CARE,
    SpecialtyId.URGENT_CARE,
    SpecialtyId.COVID_TESTING_MOBILE,
    SpecialtyId.COVID_TRAVEL_CLEARANCE,
  ],
  WITHOUT_PC_OCC: [
    SpecialtyId.URGENT_CARE,
    SpecialtyId.COVID_TESTING_MOBILE,
    SpecialtyId.COVID_TRAVEL_CLEARANCE,
  ],
  WITHOUT_PC_OCC_TRAVEL: [SpecialtyId.URGENT_CARE, SpecialtyId.COVID_TESTING_MOBILE],
  ONLY_PC: [SpecialtyId.PRIMARY_CARE],
  ONLY_TRAVEL: [SpecialtyId.COVID_TRAVEL_CLEARANCE],
};

export const CareSlugList = {
  HOME_WITH_ALL: [
    CareSlug.PRIMARY_CARE,
    CareSlug.URGENT_CARE,
    CareSlug.COVID_CARE,
    CareSlug.ANNUAL_PHYSICAL,
    CareSlug.COLD_AND_FLU,
    CareSlug.WORKPLACE_HEALTH,
    CareSlug.COVID_TRAVEL_TESTING,
    CareSlug.INJURY,
  ],
  HOME_WITHOUT_PC: [
    CareSlug.URGENT_CARE,
    CareSlug.COVID_CARE,
    CareSlug.COLD_AND_FLU,
    CareSlug.WORKPLACE_HEALTH,
    CareSlug.COVID_TRAVEL_TESTING,
    CareSlug.INJURY,
    CareSlug.URINARY_TRACT_INFECTION,
    CareSlug.RASH,
  ],
  HOME_WITHOUT_OCC: [
    CareSlug.PRIMARY_CARE,
    CareSlug.URGENT_CARE,
    CareSlug.COVID_CARE,
    CareSlug.ANNUAL_PHYSICAL,
    CareSlug.COLD_AND_FLU,
    CareSlug.COVID_TRAVEL_TESTING,
    CareSlug.INJURY,
    CareSlug.URINARY_TRACT_INFECTION,
  ],
  HOME_WITHOUT_PC_OCC: [
    CareSlug.URGENT_CARE,
    CareSlug.COVID_CARE,
    CareSlug.COLD_AND_FLU,
    CareSlug.COVID_TRAVEL_TESTING,
    CareSlug.INJURY,
    CareSlug.RASH,
    CareSlug.URINARY_TRACT_INFECTION,
    CareSlug.EAR_ACHE,
  ],
  CLINIC_WITH_ALL: [
    CareSlug.PRIMARY_CARE,
    CareSlug.URGENT_CARE,
    CareSlug.COVID_CARE,
    CareSlug.ANNUAL_PHYSICAL,
    CareSlug.COLD_AND_FLU,
    CareSlug.WORKPLACE_HEALTH,
    CareSlug.COVID_TRAVEL_TESTING,
    CareSlug.INJURY,
  ],
  CLINIC_WITHOUT_PC: [
    CareSlug.URGENT_CARE,
    CareSlug.COVID_CARE,
    CareSlug.COLD_AND_FLU,
    CareSlug.WORKPLACE_HEALTH,
    CareSlug.COVID_TRAVEL_TESTING,
    CareSlug.INJURY,
    CareSlug.URINARY_TRACT_INFECTION,
    CareSlug.RASH,
  ],
  CLINIC_WITHOUT_OCC: [
    CareSlug.PRIMARY_CARE,
    CareSlug.URGENT_CARE,
    CareSlug.COVID_CARE,
    CareSlug.ANNUAL_PHYSICAL,
    CareSlug.COLD_AND_FLU,
    CareSlug.COVID_TRAVEL_TESTING,
    CareSlug.INJURY,
    CareSlug.URINARY_TRACT_INFECTION,
  ],
  CLINIC_WITHOUT_PC_OCC: [
    CareSlug.URGENT_CARE,
    CareSlug.COVID_CARE,
    CareSlug.COLD_AND_FLU,
    CareSlug.COVID_TRAVEL_TESTING,
    CareSlug.INJURY,
    CareSlug.URINARY_TRACT_INFECTION,
    CareSlug.RASH,
    CareSlug.EAR_ACHE,
  ],
  CLINIC_WITHOUT_PC_OCC_TRAVEL: [
    CareSlug.URGENT_CARE,
    CareSlug.COVID_CARE,
    CareSlug.COLD_AND_FLU,
    CareSlug.INJURY,
    CareSlug.URINARY_TRACT_INFECTION,
    CareSlug.RASH,
    CareSlug.EAR_ACHE,
    CareSlug.SORE_THROAT,
  ],
  CLINIC_WITH_TRAVEL_ONLY: [
    CareSlug.COVID_TRAVEL_TESTING,
    CareSlug.SAME_DAY_RT_PCR,
    CareSlug.SAME_DAY_ANTIGEN,
    CareSlug.SAME_DAY_NAAT,
    CareSlug.NEXT_DAY_RT_PCR,
  ],
  CLINIC_WITH_PRIMARY_ONLY: [
    CareSlug.ANNUAL_PHYSICAL,
    CareSlug.WELLNESS_VISIT,
    CareSlug.WOMENS_HEALTH,
    CareSlug.LGBTQ_HEALTH,
    CareSlug.EXISTING_CONDITION,
    CareSlug.FAMILY_PLANNING,
    CareSlug.STI,
    CareSlug.SPORTS_PHYSICAL,
  ],
};

export const ListsByPath = {
  [v5Pages.home]: [
    {
      specialtyIds: AllowedSpecialtyIdList.ALL,
      careSlugs: CareSlugList.HOME_WITH_ALL,
    },
    {
      specialtyIds: AllowedSpecialtyIdList.WITHOUT_PC,
      careSlugs: CareSlugList.HOME_WITHOUT_PC,
    },
    {
      specialtyIds: AllowedSpecialtyIdList.WITHOUT_OCC,
      careSlugs: CareSlugList.HOME_WITHOUT_OCC,
    },
    {
      specialtyIds: AllowedSpecialtyIdList.WITHOUT_PC_OCC,
      careSlugs: CareSlugList.HOME_WITHOUT_PC_OCC,
    },
  ],

  [v5Pages.clinicDetails]: [
    {
      specialtyIds: AllowedSpecialtyIdList.ALL,
      careSlugs: CareSlugList.CLINIC_WITH_ALL,
    },
    {
      specialtyIds: AllowedSpecialtyIdList.WITHOUT_PC,
      careSlugs: CareSlugList.CLINIC_WITHOUT_PC,
    },
    {
      specialtyIds: AllowedSpecialtyIdList.WITHOUT_OCC,
      careSlugs: CareSlugList.CLINIC_WITHOUT_OCC,
    },
    {
      specialtyIds: AllowedSpecialtyIdList.WITHOUT_PC_OCC,
      careSlugs: CareSlugList.CLINIC_WITHOUT_PC_OCC,
    },
    {
      specialtyIds: AllowedSpecialtyIdList.WITHOUT_PC_OCC_TRAVEL,
      careSlugs: CareSlugList.CLINIC_WITHOUT_PC_OCC_TRAVEL,
    },
    {
      specialtyIds: AllowedSpecialtyIdList.ONLY_TRAVEL,
      careSlugs: CareSlugList.CLINIC_WITH_TRAVEL_ONLY,
    },
    {
      specialtyIds: AllowedSpecialtyIdList.ONLY_PC,
      careSlugs: CareSlugList.CLINIC_WITH_PRIMARY_ONLY,
    },
  ],
};

const isValidPath = (pathname: string): pathname is keyof typeof ListsByPath =>
  Object.keys(ListsByPath).includes(pathname);

export const getFeaturedCareSlugs = memoizee(
  (pathname: string, specialtyIds: string[] = allowedSpecialtyIds): CareSlug[] => {
    const key: keyof typeof ListsByPath | null = isValidPath(pathname) ? pathname : null;
    const filteredSpecialtyIds = specialtyIds.filter(isAllowedSpecialtyId);

    const defaultList =
      pathname === v5Pages.home
        ? CareSlugList.HOME_WITHOUT_PC_OCC
        : CareSlugList.CLINIC_WITHOUT_PC_OCC_TRAVEL;

    return key
      ? ListsByPath[key].find(set =>
          doListsContainSameElements(
            filteredSpecialtyIds,
            set.specialtyIds.filter(isAllowedSpecialtyId),
          ),
        )?.careSlugs || defaultList
      : defaultList;
  },
  {
    normalizer: JSON.stringify,
    maxAge: MsMap.ONE_MINUTE,
  },
);

export const useFeaturedCareSlugs = (specialtyIdsOverride?: string[]): CareSlug[] => {
  const {pathname} = useRouter();
  const {locations, locationsSorted} = useTypedSelector(state => state.config);
  const selectedRegion = useTypedSelector(selectSelectedRegion);
  const map =
    !specialtyIdsOverride &&
    locationsSorted &&
    selectedRegion &&
    locations &&
    buildRegionSpecialtyMap(locations);

  const specialtyIds =
    specialtyIdsOverride || (map && map[selectedRegion]?.filter(isAllowedSpecialtyId)) || undefined;
  return getFeaturedCareSlugs(pathname, specialtyIds);
};
